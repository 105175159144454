<template>
    <uw-content title="员工管理">

        <!-- 菜单 -->
        <template slot="suffix">
            <el-button type="primary" size="mini" @click="$refs.employeeCreate.Open()">新建员工</el-button>
        </template>

        <!-- 统计数据 -->
        <div :style="{ display: 'grid', gridTemplateColumns: 'calc(20% - 5px) calc(80% - 5px)', columnGap: '10px' }">
            <div style="background-color: #f4f5f7; border-radius: 3px; padding: 15px; display: flex;">
                <el-statistic :style="{ lineHeight: 2, cursor: 'pointer' }" @click.native="search.status = 0; TableSearch()">
                    <template slot="title"><span :style="{ color: search.status == 0 ? '#0027ff' : '#606266' }">在职</span></template>
                    <template slot="formatter"><span :style="{ color: search.status == 0 ? '#0027ff' : '#606266' }">{{ gb.type['在职'] }}</span></template>
                </el-statistic>
                <el-statistic :style="{ lineHeight: 2, cursor: 'pointer' }" @click.native="search.status = 1; TableSearch()">
                    <template slot="title"><span :style="{ color: search.status == 1 ? '#0027ff' : '#606266' }">离职</span></template>
                    <template slot="formatter"><span :style="{ color: search.status == 1 ? '#0027ff' : '#606266' }">{{ gb.type['离职'] }}</span></template>
                </el-statistic>
            </div>
            <div style="background-color: #f4f5f7; border-radius: 3px; padding: 15px; display: flex;">
                <el-statistic :style="{ lineHeight: 2, cursor: 'pointer' }" @click.native="search.type = '全职'; TableSearch()">
                    <template slot="title"><span :style="{ color: search.type == '全职' ? '#0027ff' : '#606266' }">全职</span></template>
                    <template slot="formatter"><span :style="{ color: search.type == '全职' ? '#0027ff' : '#606266' }">{{ gb.type['全职'] }}</span></template>
                </el-statistic>
                <el-statistic :style="{ lineHeight: 2, cursor: 'pointer' }" @click.native="search.type = '兼职'; TableSearch()">
                    <template slot="title"><span :style="{ color: search.type == '兼职' ? '#0027ff' : '#606266' }">兼职</span></template>
                    <template slot="formatter"><span :style="{ color: search.type == '兼职' ? '#0027ff' : '#606266' }">{{ gb.type['兼职'] }}</span></template>
                </el-statistic>
                <el-statistic :style="{ lineHeight: 2, cursor: 'pointer' }" @click.native="search.type = '试用'; TableSearch()">
                    <template slot="title"><span :style="{ color: search.type == '试用' ? '#0027ff' : '#606266' }">试用</span></template>
                    <template slot="formatter"><span :style="{ color: search.type == '试用' ? '#0027ff' : '#606266' }">{{ gb.type['试用'] }}</span></template>
                </el-statistic>
                <el-statistic :style="{ lineHeight: 2, cursor: 'pointer' }" @click.native="search.type = '实习'; TableSearch()">
                    <template slot="title"><span :style="{ color: search.type == '实习' ? '#0027ff' : '#606266' }">实习</span></template>
                    <template slot="formatter"><span :style="{ color: search.type == '实习' ? '#0027ff' : '#606266' }">{{ gb.type['实习'] }}</span></template>
                </el-statistic>
                <el-statistic :style="{ lineHeight: 2, cursor: 'pointer' }" @click.native="search.type = '劳务'; TableSearch()">
                    <template slot="title"><span :style="{ color: search.type == '劳务' ? '#0027ff' : '#606266' }">劳务</span></template>
                    <template slot="formatter"><span :style="{ color: search.type == '劳务' ? '#0027ff' : '#606266' }">{{ gb.type['劳务'] }}</span></template>
                </el-statistic>
                <el-statistic :style="{ lineHeight: 2, cursor: 'pointer' }" @click.native="search.type = '顾问'; TableSearch()">
                    <template slot="title"><span :style="{ color: search.type == '顾问' ? '#0027ff' : '#606266' }">顾问</span></template>
                    <template slot="formatter"><span :style="{ color: search.type == '顾问' ? '#0027ff' : '#606266' }">{{ gb.type['顾问'] }}</span></template>
                </el-statistic>
                <el-statistic :style="{ lineHeight: 2, cursor: 'pointer' }" @click.native="search.type = '返聘'; TableSearch()">
                    <template slot="title"><span :style="{ color: search.type == '返聘' ? '#0027ff' : '#606266' }">返聘</span></template>
                    <template slot="formatter"><span :style="{ color: search.type == '返聘' ? '#0027ff' : '#606266' }">{{ gb.type['返聘'] }}</span></template>
                </el-statistic>
                <el-statistic :style="{ lineHeight: 2, cursor: 'pointer' }" @click.native="search.type = '外包'; TableSearch()">
                    <template slot="title"><span :style="{ color: search.type == '外包' ? '#0027ff' : '#606266' }">外包</span></template>
                    <template slot="formatter"><span :style="{ color: search.type == '外包' ? '#0027ff' : '#606266' }">{{ gb.type['外包'] }}</span></template>
                </el-statistic>
            </div>
        </div>

        <!-- 表格 -->
        <div :style="{ height: 'calc(100% - 80px)' }">
            <uw-table :show-footer="false">
                
                <!-- 检索 -->
                <template slot="search">
                    <hrm-organize-cascader @onChange="TableSearch" v-model="search.organize_id" />
                    <el-input clearable v-model="search.name" @input="$lodash.debounce(TableSearch, 500)" size="mini" placeholder="员工姓名"></el-input>
                    <el-input clearable v-model="search.phone" @input="$lodash.debounce(TableSearch, 500)" size="mini" placeholder="手机号码"></el-input>
                </template>

                <!-- 按钮 -->
                <template slot="button">
                    <el-button type="primary" size="mini" @click="$refs.xTable.print()" >打印</el-button>
                    <el-button type="primary" size="mini" @click="$refs.xTable.openExport()" >导出</el-button>
                </template>

                <!-- 表数据 -->
                <template slot="data">
                    <!-- 表内容 -->
                    <vxe-table
                        ref="xTable"
                        :data="table"
                        :loading="loading"
                        :export-config="{}"
                        :print-config="{}"
                        height="100%">

                        <!-- 序号 -->
                        <vxe-column width="60" type="seq" title="序号" fixed="left"></vxe-column>

                        <!-- 员工名称 -->
                        <vxe-column width="120" field="name" title="员工名称" fixed="left">
                            <template #default="{ row }">
                                <div style="display: flex; align-items: center; justify-content: flex-start;">
                                    <el-avatar style="margin-right: 5px;" :size="26" v-if="row.avatar" :src="row.avatar" />
                                    <el-avatar style="margin-right: 5px;" :size="26" v-else> ? </el-avatar>
                                    <el-link type="primary" @click="$refs.employeeDrawer.Open(row.id)">{{  row.name }}</el-link>
                                </div>
                            </template>
                        </vxe-column>

                        <!-- 性别 -->
                        <vxe-column show-overflow width="60" field="sex" title="性别">
                            <template #default="{ row }">
                                <span v-if="row.sex == 0" style="color: #909399">未知</span>
                                <span v-if="row.sex == 1" style="color: #409EFF">男</span>
                                <span v-if="row.sex == 2" style="color: #F56C6C">女</span>
                            </template>
                        </vxe-column>

                        <vxe-column width="80" field="status" title="在职状态">
                            <template v-slot="{ row }">
                                <span v-if="row.status == 0" style="color: #409EFF">在职</span>
                                <span v-else style="color: #F56C6C">离职</span>
                            </template>
                        </vxe-column>
                        
                        <vxe-column width="120" field="type" title="聘用方式"></vxe-column>

                        <!-- 组织/部门 -->
                        <vxe-column width="160" field="organize.name" title="组织/部门">
                            <template #default="{ row }">
                                <el-link :underline="false" type="primary" @click="$refs.organizeDrawer.Open(row.organize_id)">{{ row.organize?.name }}</el-link>
                                <el-tag :style="{ marginLeft: '5px' }" size="mini" effect="dark" type="danger" v-if="row.organize_admin">负责人</el-tag>
                            </template>
                        </vxe-column>

                        <!-- 岗位 -->
                        <vxe-column width="120" field="appointment" title="岗位"></vxe-column>

                        <!-- 联系方式 -->
                        <vxe-column width="120" field="phone" title="手机"></vxe-column>
                        <vxe-column width="160" field="email" title="邮箱"></vxe-column>
                        <vxe-column width="100" field="number" title="工号"></vxe-column>

                        <!-- 企业微信 -->
                        <vxe-column width="80" field="wecom_status" title="企业微信">
                            <template #default="{ row }">
                                <span v-if="row.wecom_status" style="color: #409EFF">已绑定</span>
                                <span v-else style="color: #909399">未绑定</span>
                            </template>
                        </vxe-column>

                        

                        <!-- 用户权限/岗位 -->
                        <vxe-column min-width="200" field="role" title="角色权限">
                            <template #default="{ row }">
                                <span v-for="(item, idx) in row.role" style="color: #409EFF" :key="idx">{{ item.name }}&nbsp;&nbsp;&nbsp;</span>
                            </template>
                        </vxe-column>
                    </vxe-table>
                </template>

            </uw-table>
        </div>

        <!-- 组件 -->
        <hrm-employee-create ref="employeeCreate" @onChange="TableSearch()" />
        <hrm-employee-drawer ref="employeeDrawer" @onChange="TableSearch()" />
        <hrm-organize-drawer ref="organizeDrawer" @onChange="TableSearch()" />
        
    </uw-content>
</template>

<script>
export default {
    data () {
        return {
            // 加载状态
            loading: false,

            // 数据
            table: [],
            gb: {
                type: {}
            },

            // 检索
            search: {
                name: null,
                organize_id: null,
                status: 0,
                phone: null,
                type: '全职',
                children: true
            },

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },
        }
    },

    mounted () {
        this.TableSearch()
    },

    methods: {
        /**
        |--------------------------------------------------
        | 表格数据 - 查询
        |--------------------------------------------------
        |
        */

        async TableSearch() {
            this.loading = true
            
            this.$http.post(
                '/9api/hrm/employee/search-all',
                {
                    search: this.search,
                    order: this.order,
                    hidden: ['wecom', 'password'],
                    append: ['role', 'organize', 'wecom_status']
                }
            ).then((rps) => {
                this.table = rps.data
                this.loading = false
            })

            this.$http.post('/9api/hrm/employee/gb-type').then((rps) => {
                this.gb.type = rps.data
            })
            
        }
    }
}
</script>